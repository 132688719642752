<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <b-card-header class="pb-50">
        <b-button
          class="btn-icon"
          variant="flat"
          style="padding: 0"
          @click="visible = !visible"
        >
          <h4 :class="{ 'mb-1': !visible }">
            <feather-icon v-if="!visible" size="20" icon="ChevronDownIcon" />
            <feather-icon v-else size="20" icon="ChevronUpIcon" />
            <span> {{ costCenter }} </span>
          </h4>
        </b-button>
        <b-card-text v-if="visible" class="mb-1">
          <b-badge variant="primary" class="mr-1">
            <span>Arraste para o lado</span>
            <feather-icon icon="ChevronsRightIcon" class="ml-25" />
          </b-badge>
        </b-card-text>
      </b-card-header>
      <b-table
        v-if="visible"
        hover
        class="position-relative text-center lead-table"
        :items="tableItems"
        :fields="tableFields"
        responsive
        show-empty
        empty-text="Não foram encontrados competências para ser exibidas"
        :busy="loadingIndividual"
        :tbody-tr-class="rowClass"
      >
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";

export default {
  props: {
    costCenter: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    loadingIndividual: Boolean,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      tableFields: [],
      tableItems: [],
      visible: false,
    };
  },
  mounted() {
    this.initializeTable();
  },
  methods: {
    initializeTable() {
      const months = Object.keys(this.data);
      this.tableFields = [
        { key: "name", label: "Categorias" },
        ...months.map((month) => ({ key: month, label: month })),
      ];

      const categories = {};
      const totals = {};

      months.forEach((month) => {
        this.data[month].forEach((category) => {
          if (!categories[category.name]) {
            categories[category.name] = { name: category.name };
          }
          categories[category.name][month] = toCurrency(category.amount);

          if (!totals[month]) {
            totals[month] = 0;
          }
          totals[month] += category.amount;
        });
      });

      this.tableItems = Object.values(categories);

      const totalRow = { name: "Total" };

      months.forEach((month) => {
        (totalRow[month] = toCurrency(totals[month])) || 0;
      });
      this.tableItems.push(totalRow);
    },
    rowClass(item, type) {
      let colorClass = "";
      if (!item || type !== "row") {
        return;
      }
      if (item.name === "Total") {
        if (this.skin === "dark") {
          colorClass = "total-dark-color";
        } else if (this.skin === "light") {
          colorClass = "table-primary";
        }
        return colorClass;
      }
    },
  },
};
</script>

<style lang="scss">
.total-dark-color {
  background-color: #ff7f50;

  &:hover {
    background-color: rgba(255, 127, 80, 0.8) !important;
  }

  td {
    color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

.lead-table .phone {
  color: #e3e3e3;
}

.dark-layout .lead-table .phone {
  color: #676d7d;
}

.lead-table .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.lead-table .name-content .name {
  width: auto;
}

.lead-table .name-content .name:hover {
  background-color: #f26237;
}

.lead-table .name-content .whats {
  width: 27px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.lead-table .name-content .whats:hover {
  background-color: #f26237;
}

.lead-table .btn-group .btn {
  border: unset !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.lead-table thead th {
  white-space: nowrap !important;
}
</style>
