<template>
  <b-card no-body class="mb-2">
    <div class="chart-container">
      <b-spinner v-if="loading" class="mt-2" variant="secondary" large />
      <vue-apex-charts
        v-else-if="series.length"
        type="bar"
        width="100%"
        height="300"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </b-card>
</template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters, mapActions } from "vuex";
  import * as types from "../store/types";
  import { useToast } from "vue-toastification/composition";
  import useAppConfig from "@core/app-config/useAppConfig";
  import { toCurrency } from "@/helpers/converters.js";
  
  export default {
    components: {
      VueApexCharts,
    },
    setup() {
      const { skin } = useAppConfig();
      return { toast: useToast(), skin };
    },
    data() {
      return {
        primaryColor: "#48da89",
        defaultColor: "#b4b7bd",
        loading: true,
      };
    },
    props: {
      year: Number
    },
    computed: {
      ...mapGetters({
        totalExpensesByCostCenter: types.TOTAL_EXPENSES_BY_COST_CENTER,
        expenseSearchYear: types.EXPENSES_SEARCH_YEAR        
      }),
  
      series() {
        return [
          {
            name: "Valor Total",
            data: this.totalExpensesByCostCenter.map((item) => item.total_amount),
          },
        ];
      },
  
      chartOptions() {
        return {
          chart: {
            type: 'bar',
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            categories: this.totalExpensesByCostCenter.map(
              (item) => item.cost_center
            ),
            labels: {
              style: {
                colors: this.skin === "dark" ? this.defaultColor : "",
              },
            },
          },
          yaxis: {
            title: {
              text: "Valor Total",
              style: {
                color: this.defaultColor,
              },
            },
            labels: {
              formatter: (value) => toCurrency(value),
              style: {
                colors: this.defaultColor,
              },
            },
          },
          colors: [this.primaryColor],
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Despesas Totais por Centro de Custo",
            style: {
              color: this.skin === "dark" ? this.defaultColor : "",
            },
          },
          legend: {
            labels: {
              colors: this.skin === "dark" ? this.defaultColor : "",
            },
          },
        };
      },
    },
    watch: {
      totalExpensesByCostCenter(newVal) {
        if (newVal.length) {
          this.loading = false;
        }
      },
    },
    created() {
      this.getData();
    },
    methods: {
      ...mapActions({
        getChartData: types.GET_EXPENSES_BY_COST_CENTER
      }),
      getData(){
        this.loading = true
        this.getChartData(this.expenseSearchYear)
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar os dados do grafico. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  };
  </script>
  
  <style scoped>
  .chart-container {
    width: 80%;
    height: 400px;
    margin: auto;
    padding: 20px;
  }
  </style>
  