<template>
  <b-card no-body class="mb-2">
    <b-card-header class="pb-50">
      <h4>Resumo anual Futuro Hub</h4>
    </b-card-header>
    <div class="text-center" v-if="loading">
      <b-spinner class="mt-2" variant="secondary" large />
    </div>
    <div v-else class="m-2">
      <b-table 
        striped 
        bordered 
        hover 
        :items="tableItems" 
        :fields="tableFields"
        responsive
        class="incomes-table"
      >
      </b-table>
    </div>
    <div class="chart-container" >
      <vue-apex-charts
        v-if="series.length"
        type="line"
        width="100%"
        height="300"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </b-card>
</template>
  
<script>
import _ from 'lodash'
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";
  
export default {
  components: {
    VueApexCharts,
  },
  props: {
    year: Number
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  data() {
    return {
      primaryColor: "#48da89",
      defaultColor: "#b4b7bd",
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      yearIncomeChartData: types.YEAR_INCOME_CHART_DATA,
      expenseSearchYear: types.EXPENSES_SEARCH_YEAR
    }),
    series() {
      return this.yearIncomeChartData?.series || [];
    },
    categories() {
      return this.yearIncomeChartData?.categories || [];
    },
    tableItems() {
      const lines = _.map(this.series, (item) => {
        const values = _.map(item.data, (value) => toCurrency(value, 0, 2, true))
        const months = _.map(this.categories, (e, index) => {
          return { [e]: values[index] }
        })
        const total = _.sum(item.data)
        return _.merge( { desc: item.name }, ...months, { total: toCurrency(total, 0, 2, true)} )
      })
      return lines
    },
    tableFields() {
      const months = _.map(this.categories, (e) => {
        return { key: e, label: e, sortable: false, trClass: 'p-0' }
      })
      return [
        { key: 'desc', label: 'Meses', sortable: false },
        ...months,
        { key: 'total', label: 'Total', sortable: false }
      ]
    },
    chartOptions() {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          foreColor: this.skin === "dark" ? "#fff" : "",
        },
        legend: {
          position: 'top'
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          colors: ["#247BA0", "#FF1654", "#FFC107", "#28C76F"],
        },
        colors: ["#247BA0", "#FF1654", "#FFC107", "#28C76F"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              toCurrency(data.series[data.seriesIndex][data.dataPointIndex])
            }</span></div>`
          },
          followCursor: true
        },
        xaxis: {
          categories:this.categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return toCurrency(value) ;
            }
          },
        },
      }
    },
  },
  watch: {
    totalExpensesByCostCenter(newVal) {
      if (newVal.length) {
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapActions({
      getYearIncomeChartData: types.GET_YEAR_INCOME_CHART_DATA
    }),
    getData(){
      this.loading = true
      this.getYearIncomeChartData(this.expenseSearchYear)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os dados de receita. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  width: 80%;
  height: 400px;
  margin: auto;
  padding: 20px;
}
</style>
<style lang="scss">
.incomes-table td{
  padding: 0.65rem !important;
}
</style>

